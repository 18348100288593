import {Component} from 'react'
import DataService from './DataService'
import MessageService from './MessageService'

class FormService extends Component {
    static toJson(id) {
        let data = new FormData(document.getElementById(id));

        let entity = {};
        data.forEach(function (value, key) {
            entity[key] = value;
        });

        return JSON.stringify(entity);
    }

    static disableSubmit(id) {
        let submit = document.getElementById(id);
        if (!submit) return;

        submit.disabled = true;
        submit.innerHTML += '...';
    }

    static enableSubmit(id) {
        let submit = document.getElementById(id);
        if (!submit) return ;

        submit.disabled = false;
        submit.innerHTML = submit.innerHTML.replace('...', '');
    }

    static clean(id) {
        let form = document.getElementById(id);
        form.reset();
        form.elements[0].focus();
    }

    static getValidationMessage(input, t) {
        console.log(input.validity);

        if (input.validity.rangeOverflow) return t('Range overflow') + '.';
        if (input.validity.patternMismatch) return t('Pattern mismatch') + '.';
        if (input.validity.rangeUnderflow) return t('Range underflow') + '.';
        if (input.validity.tooLong) return t('Too long') + '.';
        if (input.validity.typeMismatch) return t('Type mismatch') + '.';
        if (input.validity.valueMissing) return t('Required') + '.';
        if (input.validity.badInput) return t('Type mismatch') + '.';
    }

    static validateData(id, t) {
        let form = document.getElementById(id);
        let inputs = form.elements;

        for (let i=0; i<inputs.length; i++) {
            inputs[i].addEventListener('input', () => {
                let label = document.querySelector('label[for="' + inputs[i].id + '"]');
                let error = label.getElementsByTagName('span')[0];

                if (inputs[i].checkValidity()) {
                    error.textContent = "";
                    error.style.display = 'none';
                }
                else {
                    error.textContent = " " + FormService.getValidationMessage(inputs[i], t);
                    error.style.display = 'inline';
                }
            });
        }
    }

    static isValid(id, t, focus_first_issue=true) {
        let form = document.getElementById(id);
        let inputs = form.elements;
        let is_valid = true;

        let validation_groups = document.getElementsByClassName("validation-group");
        for (let x of validation_groups) {
            x.classList.remove("validation-group-error");
        }

        let first_input = null;

        for (let i=0; i<inputs.length; i++) {
            let label = document.querySelector('label[for="' + inputs[i].id + '"]');
            if (!label) continue;

            let error_span = label.getElementsByTagName('span')[0];
            if (!error_span) {
                error_span = document.createElement('span');
                error_span.classList.add('MuiFormLabel-asterisk');
                label.appendChild(error_span);
            }

            error_span.textContent = "";
            error_span.style.display = 'inline';

            if (inputs[i].checkValidity() === true) continue;
            if (!first_input) first_input = inputs[i];
            error_span.textContent = " " + FormService.getValidationMessage(inputs[i], t);

            for (let x of validation_groups) {
                let group = document.getElementById(x.getAttribute("groupid"));
                if (group) {
                    let input = group.querySelector('#' + inputs[i].id);
                    if (input) x.classList.add("validation-group-error");                    
                }
            }

            is_valid = false;
        }

        if (!is_valid && focus_first_issue) {
            window.scrollTo(0, 0);
            if (first_input) first_input.focus();
        }

        return is_valid;
    }

    static showValidationMessages(input_id, message) {
        if (!message) return;

        let label = document.querySelector('label[for="' + input_id + '"]');
        if (!label) return;

        let error = label.getElementsByTagName('span')[0];
        error.textContent = " " + message;
        error.style.display = 'inline';        
    }

    static showValidationMessagesByDict(errors, t) {
        if (errors["error"]) errors = errors["error"];
		let messages = [];

        for (var key in errors) {
            let error = errors[key];
            if (Array.isArray(error)) error = error.join(', ');
			
			let label = document.querySelector('label[for="' + key + '"]');
			if (label) messages.push("<li>" + t(label.textContent) + ": " + t(DataService.capitalize(error)) + "</li>");

            FormService.showValidationMessages(key, t(DataService.capitalize(error)));
        }

		if (messages.length > 0) {
            MessageService.showWarningNotification("<u>" + t("Verify the following validations") + ":</u><br/><ul>" + messages.join("") + "</ul>");
		}
    }

    static enterNavigation() {
        const delegate = (el, evt, sel, handler) => {
            el.addEventListener(evt, function(event) {
                var t = event.target;
                while (t && t !== this) {
                    if (t.matches(sel)) handler.call(t, event);
                    t = t.parentNode;
                }
            });
        }

        delegate(document, "keypress", "input,select", function(event) {
            if (event.which !== 13) return;

            event.preventDefault();
            event.stopPropagation();

            let items = document.querySelectorAll("input,select,textarea,button[type='submit']");
            items = Array.prototype.slice.call(items);
            let index = items.indexOf(this);
            if (index >= items.length -1) return;

            // if (index >= items.length -1) {
            //     let form = items[index].closest('form');
            //     form.querySelector('button').focus();
            //     return;
            // }

            while((items[index+1].offsetWidth <= 0 || items[index+1].offsetHeight <= 0)) {
                index++;
                if (index >= items.length -1) return;

                // if (index >= items.length -1) {
                //     let form = items[index].closest('form');
                //     form.querySelector('button').focus();
                //     return;
                // }
            }

            items[index+1].focus();
        });
    }
}

export default FormService;
