import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
//-----------------------------------------------------------------------
import AiAgentSvg from 'layout/MainLayout/Header/AiAgentSection/AiAgentSvg'

import Loading from "ui-component/Loading";
import CookieService from "services/CookieService";
import HtmlService from "services/HtmlService";
import ApiService from "services/ApiService";
import SecurityService from "services/SecurityService";
//-----------------------------------------------------------------------

const AiAgent = () => {
    const { t } = useTranslation();

    const theme = {
      primary_color: '222f69',
      secondary_color: '068054',
      success_color: '39bb6c',
      info_color: '4eb6cc',
      warning_color: 'f48f36',
      error_color: 'ca4040',
      neutral_color: 'd5d5d5',
      empty_color: '7d7d7d',
      font_family: 'Poppins'
    }
  
    const [current_locale, setCurrentLocale] = useState(CookieService.getLocale());
    const [url, setUrl] = useState(null);
    const [loading, setLoading] = useState(true);

    const updateCurrentLocale = (() => {
        setCurrentLocale(CookieService.getLocale());
    });

    const updateUrl = (() => {
      ApiService.validateExpiration(t, () => {
        setUrl(getUrl());
      });
    });

    const onRecievedMessage = (event) => {
        event.stopPropagation();

        if (event.data.constructor !== Object) return;
        if (event.data.type !== 'ai-agent-message-action') return;

        if (event.data.action !== 'send_startup_alerts') closeAiAgent();
        HtmlService.parseAiAgentAction(t, event.data.action);
    };

    useEffect(() => {
      if (!url) {
        return updateUrl();
      }

      if (current_locale !== CookieService.getLocale()) {
          updateCurrentLocale();
          updateUrl();
      }

      // Register AI Agent actions listener...
      window.addEventListener("message", onRecievedMessage);

      return () => {
          window.removeEventListener("message", onRecievedMessage)
      }
    });

    function getUrl() {
      let url = window.env.GENERIC_FRONTEND_REACTJS_AI_AGENT +
        "/?subdomain=customs" +
        "&user_id=" + SecurityService.getUserId() +
        "&token=" + SecurityService.getUserToken() +
        "&embebbed=true" +
        "&locale=" + CookieService.getLocale() +
        "&primary_color=" + theme.primary_color +
        "&secondary_color=" + theme.secondary_color +
        "&success_color=" + theme.success_color +
        "&info_color=" + theme.info_color +
        "&warning_color=" + theme.warning_color +
        "&error_color=" + theme.error_color +
        "&neutral_color=" + theme.neutral_color +
        "&empty_color=" + theme.empty_color +
        "&font_family=" + theme.font_family;

        return url;
    }

    const onAiAgentLoad = () => {
        if (!loading) return;
        setLoading(false);
    }

    const closeAiAgent = () => {
        document.getElementById('ai-agent').classList.remove('oppened');
    }

    return (
      <div id="ai-agent">
        {
          loading &&
          <Loading style={{marginTop: '25px'}} />
        }
    
        {
          !loading &&
          <div id="ai-agent-title">
            <h1>
              <div style={{float: 'left', marginTop: '-4px', marginRight: '5px'}}>
                <AiAgentSvg color="#364152" />
              </div>
              {t('AI Agent')}
            </h1>
            <ClearIcon id="ai-agent-close" onClick={() => closeAiAgent()} />
          </div>
        }

        {
          url &&
          <iframe 
            id="ai-agent-iframe"
            title="ai-agent"
            onLoad={onAiAgentLoad}
            src={url}
            style={{display: (loading ? 'none' : 'block')}}
          />
        }
      </div>
    );
};

export default AiAgent;
