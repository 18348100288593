import {Component} from 'react'
import axios from 'axios';
import MessageService from "./MessageService"
import SecurityService from "./SecurityService";

class ApiService extends Component {
    static active_requests_exits = {};
    static pending_requests = {};

    static showSuccessNotification(t) {
        MessageService.showSuccessNotification(
            t('The operation was successful')
        );
    }

    static showWarningNotification(t) {
        MessageService.showWarningNotification(
            t('The operation could not be completed. Please, try again')
        );
    }

    static showDeleteWarningNotification(t) {
        MessageService.showWarningNotification(
            t('The operation could not be completed. Possibly the instance has related records')
        );
    }

    static validateExpiration(t, action) {
        if (SecurityService.tokenHasExpired()) {
          axios.get(window.env.GENERIC_BACKEND_RAILS_ACCOUNT + '/users/refresh_token', {
              headers: {
                  'token': SecurityService.getUserToken(),
                  'project': 'CUSTOMS'
              }
          })
          .then(response => {
            SecurityService.refreshUserToken(response.data.token, response.data.token_valid_minutes);
            action();
          })
          .catch((error) => {
              ApiService.showWarningNotification(t);
              console.error(error);
          });

          return;
        }

        action();
    }

    static execPendingRequests(t, service_url) {
        if (!ApiService.pending_requests[service_url]) return;

        let request_data = ApiService.pending_requests[service_url];
        ApiService.pending_requests[service_url] = null;

        if (request_data.method === 'get') {
            ApiService.requestEndpoint(t, request_data.service_url, request_data.endpoint, request_data.onSuccess, request_data.onError);
            return;
        }

        if (request_data.method === 'post') {
            ApiService.postEndpoint(t, request_data.service_url, request_data.endpoint, request_data.data, request_data.onSuccess, request_data.onError, request_data.show_notification);
            return;
        }     

        if (request_data.method === 'put') {
            ApiService.putEndpoint(t, request_data.service_url, request_data.endpoint, request_data.data, request_data.onSuccess, request_data.onError, request_data.show_notification);
            return;
        }

        if (request_data.method === 'delete') {
            ApiService.deleteEndpoint(t, request_data.service_url, request_data.endpoint, request_data.onSuccess, request_data.onError, request_data.show_notification);
            return;
        }
    }

    static requestEndpoint(t, service_url, endpoint, onSuccess, onError=null) {
        if (ApiService.active_requests_exits[service_url] || ApiService.pending_requests[service_url]) {
            // If active request exists, the next request will be pending...
            // or
            // If pending requests exists, pending requests is updated...
            // So, after active request is finished, only the last one pending will be executed...
            ApiService.pending_requests[service_url] = {method: 'get', endpoint: endpoint, onSuccess: onSuccess, onError: onError, service_url: service_url}
            return;
        }

        ApiService.active_requests_exits[service_url] = true;

        ApiService.validateExpiration(t, () => {
            axios.get(service_url + '/' + endpoint, {
                headers: {
                    'token': SecurityService.getUserToken(),
                    'project': 'CUSTOMS'
                }
            })
            .then(response => {
                ApiService.active_requests_exits[service_url] = false;
                if (ApiService.pending_requests[service_url]) return;

                onSuccess(response.data);
            })
            .catch((error) => {
                ApiService.active_requests_exits[service_url] = false;

                if (ApiService.pending_requests[service_url]) return;

                if (onError) {
                    let message = null;
                    if (error.response) message = error.response.data;
                    else message = error.message;

                    if (message["error"]) message = message["error"];
                    if (message.error) message = message.error;

                    onError(error.response.status, message);
                }

                ApiService.showWarningNotification(t);
                console.error(error);
            })
            .then(() => {
                ApiService.execPendingRequests(t, service_url);
            });
        });
    }

    static postEndpoint(t, service_url, endpoint, data, onSuccess, onError=null, show_notification=true, show_error_notification=true) {
        if (ApiService.active_requests_exits[service_url] || ApiService.pending_requests[service_url]) {
            // See comments on requestEndpoint function...
            ApiService.pending_requests[service_url] = {method: 'post', endpoint: endpoint, data: data, onSuccess: onSuccess, onError: onError, show_notification: show_notification, service_url: service_url}
            return;
        }

        ApiService.active_requests_exits[service_url] = true;

        ApiService.validateExpiration(t, () => {
            axios.post(service_url + '/' + endpoint, data, {
                headers: {
                    'token': SecurityService.getUserToken(),
                    'project': 'CUSTOMS'
                }
            })
            .then(response => {
                ApiService.active_requests_exits[service_url] = false;
                if (ApiService.pending_requests[service_url]) return;

                onSuccess(response.data);
                if (show_notification) ApiService.showSuccessNotification(t);
            })
            .catch((error) => {
                ApiService.active_requests_exits[service_url] = false;
                if (ApiService.pending_requests[service_url]) return;

                if (onError) {
                    if (error.response) onError(error.response.status, error.response.data);
                    else onError(error.status, error.message);
                }

                if (show_error_notification) ApiService.showWarningNotification(t);

                console.error(error);
            })
            .then(() => {
                ApiService.execPendingRequests(t, service_url);
            });
        });
    }

    static putEndpoint(t, service_url, endpoint, data, onSuccess, onError=null, show_notification=true) {
        if (ApiService.active_requests_exits[service_url] || ApiService.pending_requests[service_url]) {
            // See comments on requestEndpoint function...
            ApiService.pending_requests[service_url] = {method: 'put', endpoint: endpoint, data: data, onSuccess: onSuccess, onError: onError, show_notification: show_notification, service_url: service_url}
            return;
        }

        ApiService.active_requests_exits[service_url] = true;

        ApiService.validateExpiration(t, () => {
            axios.put(service_url + '/' + endpoint, data, {
                headers: {
                    'token': SecurityService.getUserToken(),
                    'project': 'CUSTOMS'
                }
            })
            .then(response => {
                ApiService.active_requests_exits[service_url] = false;
                if (ApiService.pending_requests[service_url]) return;

                onSuccess(response.data);
                if (show_notification) ApiService.showSuccessNotification(t);
            })
            .catch((error) => {
                ApiService.active_requests_exits[service_url] = false;
                if (ApiService.pending_requests[service_url]) return;

                if (onError) {
                    if (error.response) onError(error.response.status, error.response.data);
                    else onError(error.status, error.message);
                }

                ApiService.showWarningNotification(t);

                console.error(error);
            })
            .then(() => {
                ApiService.execPendingRequests(t, service_url);
            });
        });
    }

    static deleteEndpoint(t, service_url, endpoint, data, onSuccess, onError=null, show_notification=true) {
        if (ApiService.active_requests_exits[service_url] || ApiService.pending_requests[service_url]) {
            // See comments on requestEndpoint function...
            ApiService.pending_requests[service_url] = {method: 'delete', endpoint: endpoint, onSuccess: onSuccess, onError: onError, show_notification: show_notification, service_url: service_url}
            return;
        }

        ApiService.active_requests_exits[service_url] = true;

        ApiService.validateExpiration(t, () => {
            axios.delete(service_url + '/' + endpoint, {
                headers: {
                    'token': SecurityService.getUserToken(),
                    'project': 'CUSTOMS'
                }
            })
            .then(response => {
                ApiService.active_requests_exits[service_url] = false;
                if (ApiService.pending_requests[service_url]) return;

                onSuccess(response.data);
                if (show_notification) ApiService.showSuccessNotification(t);
            })
            .catch((error) => {
                ApiService.active_requests_exits[service_url] = false;
                if (ApiService.pending_requests[service_url]) return;

                if (onError) {
                    if (error.response) onError(error.response.status, error.response.data);
                    else onError(error.status, error.message);
                }

                ApiService.showDeleteWarningNotification(t);
            })
            .then(() => {
                ApiService.execPendingRequests(t, service_url);
            });
        });
    }

    static uploadFile(t, service_url, endpoint, param_name, file, onUploadProgress, onSuccess, onError=null, show_notification=true) {
        ApiService.validateExpiration(t, () => {
            const formData = new FormData();
            formData.append(param_name, file, file.name);

            axios.post(service_url + '/' + endpoint, formData, {
                headers: {
                    'token': SecurityService.getUserToken(),
                    'project': 'CUSTOMS'
                },
                onUploadProgress: (event) => {
                    if (onUploadProgress != null) {
                        let progress = Math.round(event.loaded / file.size * 100);
                        if (progress > 100) progress = 100;
                        onUploadProgress(progress);
                    }
                }
            })
            .then(response => {
                onSuccess(response.data);
                if (show_notification) ApiService.showSuccessNotification(t);
            })
            .catch((error) => {
                if (onError) {
                    if (error.response) onError(error.response.status, error.response.data);
                    else onError(error.status, error.message);
                }

                ApiService.showWarningNotification(t);

                console.error(error);
            })
        });
    }
}

export default ApiService;
