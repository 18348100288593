import dashboard from './dashboard';
// import pages from './pages';
import general from './general';
import trade from './trade';
import drawbacks from './drawbacks';
import accts_receivable from './accts_receivable';
import reports from './reports';
import management from './management';
import utilities from './utilities';
import security from './security';
import others from './others';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard]
};

if (general.children.length > 0) menuItems.items.push(general);
if (trade.children.length > 0) menuItems.items.push(trade);
if (drawbacks.children.length > 0) menuItems.items.push(drawbacks);
if (accts_receivable.children.length > 0) menuItems.items.push(accts_receivable);
if (reports.children.length > 0) menuItems.items.push(reports);
if (management.children.length > 0) menuItems.items.push(management);
if (utilities.children.length > 0) menuItems.items.push(utilities);
if (security.children.length > 0) menuItems.items.push(security);
if (others.children.length > 0) menuItems.items.push(others);

export default menuItems;
