import React from 'react'
import loading from 'assets/images/loading_dark.svg';
import loading_white from 'assets/images/loading.svg';
//---------------------------------------------------------------

const Loading = ({className, white, style}) => {
    return (
        <div className={'loading ' + className}>
            {
                !white &&
                <img className='loading' src={loading} style={style} alt='...' />
            }

            {
                white &&
                <img className='loading' src={loading_white} style={style} alt='...' />
            }
        </div>
    )
};
//---------------------------------------------------------------

export default Loading;
//---------------------------------------------------------------
