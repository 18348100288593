import {Component} from 'react'
import SecurityService from "./SecurityService";
import MessageService from "./MessageService";
//-------------------------------------------------------------------------

class HtmlService extends Component {
    static addLayoutScript(src) {
        let script = document.createElement("script");
        script.src = src;
        script.async = false;

        document.body.appendChild(script);
    }

    static showLoadingComponent(show_logo = false) {
        document.getElementById('loader').style.display = 'block';
        if (show_logo) document.getElementById('loader-logo').style.display = 'block';
    }

    static showContent() {
        document.getElementById('dt-root').style.display = 'block';
    }

    static resetContentScroll() {
        document.getElementById('content-wrapper').scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    static scrollToTargetInContainer(container_id, target_id) {
        const container = document.getElementById(container_id);
        if (!container) return;

        const target = document.getElementById(target_id);
        if (!target) return;

        container.scrollTo({
          top: target.offsetTop,
          behavior: 'smooth'
        });
    }

    static fullScreenMode() {
        let html = document.documentElement;

        if (html.requestFullscreen) html.requestFullscreen();
        else if (html.webkitRequestFullscreen) html.webkitRequestFullscreen();
        else if (html.msRequestFullscreen) html.msRequestFullscreen();
    }

    static exitfullScreenMode() {
        if (document.exitFullscreen) document.exitFullscreen();
        else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
        else if (document.msExitFullscreen) document.msExitFullscreen();
    }

    static sendStartupAlerts(t) {
        setTimeout(() => {
            // User profile photo alert...
            if (!SecurityService.getMainUserPhotoUrl()) {
              MessageService.showInfoNotification(t("Have you considered to update your profile avatar?"), "update_user_profile", t("Update user profile now"));
            }
            
            // User access code alert...
            if (SecurityService.userHasExpirationDate()) {
              let expiration_date = SecurityService.getPasswordExpirationDate();
              let expiration_time = SecurityService.getPasswordExpirationTime();
              MessageService.showInfoNotification(t("Your access code will expired at " + expiration_date + " " + expiration_time + ". You must to change your password as soon as possible."), "update_user_profile", t("Update user profile now"));
            }
        }, 1000);
    }

    static parseAiAgentAction(t, action) {
      if (action === 'send_startup_alerts') {
        HtmlService.sendStartupAlerts(t);
      }
      else if (action === "update_user_profile") {
          document.getElementById('update_user_profile').click();
      }
    }
}

export default HtmlService;
