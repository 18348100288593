import { useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase
} from '@mui/material';

import AiAgentSvg from './AiAgentSvg'
// ==============================|| NOTIFICATION ||============================== //

const AiAgentSection = () => {
    const theme = useTheme();

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const onShowAiAgent = () => {
		document.getElementById('ai-agent').classList.add('oppened');
    };

    return (
        <>
            <Box
                sx={{
                    ml: 0,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px', marginBottom: '0 !important' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: 'white',
                            color: 'var(--primary_color)',
                            '&:hover': {
                                background: theme.palette.primary.main,
                                color: 'white',
								stroke: 'none'
                            },
							'&:hover svg': {
                                fill: 'white'
                            }
                        }}
                        ref={anchorRef}
                        aria-haspopup="false"
                        onClick={onShowAiAgent}
                        color="inherit"
                    >

                        <AiAgentSvg />

                    </Avatar>
                </ButtonBase>
            </Box>
        </>
    );
};

export default AiAgentSection;
