// assets
import { IconUserCheck, IconUser } from '@tabler/icons';
import SecurityService from "services/SecurityService";
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const security = {
    id: 'security',
    title: 'Accounts',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('roles')) {
    security.children.push({
        id: 'roles',
        title: 'Roles',
        type: 'item',
        url: '/roles',
        icon: IconUserCheck,
        breadcrumbs: false
    });    
}

if (SecurityService.userIsGrantedTo('admin_users')) {
    security.children.push({
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/users',
        icon: IconUser,
        breadcrumbs: false
    });    
}

export default security;
