// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Divider,
    List,
    ListItem,
    ListItemText
} from '@mui/material';

// assets
import { useTranslation } from 'react-i18next';
import CookieService from 'services/CookieService';
import CheckIcon from '@mui/icons-material/Check';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const LocaleList = (props) => {
    const { onChangeLocaleGlobal } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    function changeLocale(locale) {
        i18n.changeLanguage(locale);
        onChangeLocaleGlobal(locale);
    }

    return (
        <List
            className="locale-list"
            sx={{
                width: '100%',
                maxWidth: 360,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 360
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            <ListItemWrapper>
                <ListItem alignItems="center" onClick={() => changeLocale('en')}>
                    {
						CookieService.getLocale() === 'en' &&
						<CheckIcon className="text-primary" style={{marginRight: '7px'}} />
					}
					
					{
						CookieService.getLocale() !== 'en' &&
						<CheckIcon className="text-transparent" style={{marginRight: '7px'}} />
					}

                    <ListItemText primary={t("English")} />
                </ListItem>
            </ListItemWrapper>
            
            <Divider />

            <ListItemWrapper>
                <ListItem alignItems="center" onClick={() => changeLocale('es')}>
                    {
						CookieService.getLocale() === 'es' &&
						<CheckIcon className="text-primary" style={{marginRight: '7px'}} />
					}
					
					{
						CookieService.getLocale() !== 'es' &&
						<CheckIcon className="text-transparent" style={{marginRight: '7px'}} />
					}

                    <ListItemText primary={t("Spanish")} />
                </ListItem>
            </ListItemWrapper>
        </List>
    );
};

export default LocaleList;
