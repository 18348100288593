// assets
import { IconSettings, IconPresentationAnalytics, IconMailbox } from '@tabler/icons';
import SecurityService from "services/SecurityService";
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const management = {
    id: 'management',
    title: 'Management',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('company_preferences')) {
    management.children.push({
        id: 'preferences',
        title: 'Preferences',
        type: 'item',
        url: '/preferences',
        icon: IconPresentationAnalytics,
        breadcrumbs: false
    }); 
}

if (SecurityService.userIsGrantedTo('email_templates')) {
    management.children.push({
        id: 'email_templates',
        title: 'Email Templates',
        type: 'item',
        url: '/email_templates',
        icon: IconMailbox,
        breadcrumbs: false
    }); 
}

if (SecurityService.userIsGrantedTo('lists_config')) {
    management.children.push({
        id: 'lists_config',
        title: 'Lists config',
        type: 'collapse',
        url: '/lists_config',
        icon: IconSettings,
        breadcrumbs: false,
        children: [
            {
                title: 'General / CBP / AR',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'consignee_types',
                        title: 'Consignee types',
                        type: 'item',
                        url: '/consignee_types'
                    },
                    {
                        id: 'bond_types',
                        title: 'Bond types',
                        type: 'item',
                        url: '/bond_types'
                    },
                    {
                        id: 'reconciliation_issues',
                        title: 'Reconciliation Issues',
                        type: 'item',
                        url: '/reconciliation_issues'
                    },
                    {
                        id: 'recon_location_codes',
                        title: 'Reconciliation Location codes',
                        type: 'item',
                        url: '/recon_location_codes'
                    },
                    {
                        id: 'recon_payment_types',
                        title: 'Reconciliation Payment types',
                        type: 'item',
                        url: '/recon_payment_types'
                    },
                    {
                        id: 'recon_transmission_actions',
                        title: 'Reconciliation Transmissions actions',
                        type: 'item',
                        url: '/recon_transmission_actions'
                    },
                    {
                        id: 'recon_statuses',
                        title: 'Reconciliation Statuses',
                        type: 'item',
                        url: '/recon_statuses'
                    },
                    {
                        id: 'change_options',
                        title: 'Reconciliation Change options',
                        type: 'item',
                        url: '/change_options'
                    },
                    {
                        id: 'empty_validation_actions',
                        title: 'Empty validation actions',
                        type: 'item',
                        url: '/empty_validation_actions'
                    },
                    {
                        id: 'electronic_filing_options',
                        title: 'Electronic filing options',
                        type: 'item',
                        url: '/electronic_filing_options'
                    },
                    {
                        id: 'pmt_types',
                        title: 'Pmt types',
                        type: 'item',
                        url: '/pmt_types'
                    },
                    {
                        id: 'manif_dest_from',
                        title: 'Manif dest from',
                        type: 'item',
                        url: '/manif_dest_froms'
                    },
                    {
                        id: 'consecutives',
                        title: 'Consecutives',
                        type: 'item',
                        url: '/consecutives'
                    },
                    {
                        id: 'weight_in_lines',
                        title: 'Weight in lines',
                        type: 'item',
                        url: '/weight_in_lines'
                    },
                    {
                        id: 'rel_date_mismatch_actions',
                        title: 'Rel date mismatch actions',
                        type: 'item',
                        url: '/rel_date_mismatch_actions'
                    },
                    {
                        id: 'invoice_formats',
                        title: 'Invoice formats',
                        type: 'item',
                        url: '/invoice_formats'
                    },
                    {
                        id: 'reach_limit_actions',
                        title: 'Reach limit actions',
                        type: 'item',
                        url: '/reach_limit_actions'
                    },
                    {
                        id: 'unit_metrics',
                        title: 'Unit metrics',
                        type: 'item',
                        url: '/unit_metrics'
                    },
                    {
                        id: 'teams',
                        title: 'Teams',
                        type: 'item',
                        url: '/teams'
                    },

                    {
                        id: 'bill_of_lading_types',
                        title: 'Bill of lading types',
                        type: 'item',
                        url: '/bill_of_lading_types'
                    },
                    {
                        id: 'bill_of_lading_doc_types',
                        title: 'Bill of lading doc types',
                        type: 'item',
                        url: '/bill_of_lading_doc_types'
                    },
                    {
                        id: 'filing_types',
                        title: 'Filing types',
                        type: 'item',
                        url: '/filing_types'
                    },
                    {
                        id: 'id_qualifiers',
                        title: 'ID Qualifiers',
                        type: 'item',
                        url: '/id_qualifiers'
                    },
                    {
                        id: 'shipment_types',
                        title: 'Shipment types',
                        type: 'item',
                        url: '/shipment_types'
                    },
                    {
                        id: 'activity_codes',
                        title: 'Activity codes',
                        type: 'item',
                        url: '/activity_codes'
                    },
                    {
                        id: 'routes',
                        title: 'Routes',
                        type: 'item',
                        url: '/routes'
                    },
                    {
                        id: 'holidays',
                        title: 'Holidays',
                        type: 'item',
                        url: '/holidays'
                    },
                    {
                        id: 'duty_estimates',
                        title: 'Duty Estimates',
                        type: 'item',
                        url: '/duty_estimates'
                    },
                    {
                        id: 'pga_fda_codes',
                        title: 'Pga fda codes',
                        type: 'item',
                        url: '/pga_fda_codes'
                    },
                    {
                        id: 'pga_nhtsa_codes',
                        title: 'Pga nhtsa codes',
                        type: 'item',
                        url: '/pga_nhtsa_codes'
                    },
                    {
                        id: 'pga_aphis_codes',
                        title: 'Pga aphis codes',
                        type: 'item',
                        url: '/pga_aphis_codes'
                    },
                    {
                        id: 'pga_fsis_codes',
                        title: 'Pga fsis codes',
                        type: 'item',
                        url: '/pga_fsis_codes'
                    },
                    {
                        id: 'country_codes',
                        title: 'Country codes',
                        type: 'item',
                        url: '/country_codes'
                    },
                ]
            },
            {
                title: 'Company preferences',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'smtp_ssl_modes',
                        title: 'Smtp ssl modes',
                        type: 'item',
                        url: '/smtp_ssl_modes'
                    },
                    {
                        id: 'smtp_types',
                        title: 'Smtp types',
                        type: 'item',
                        url: '/smtp_types'
                    },
                    {
                        id: 'system_on_update_actions',
                        title: 'System on update actions',
                        type: 'item',
                        url: '/system_on_update_actions'
                    },
                    {
                        id: 'sequence_modes',
                        title: 'Sequence modes',
                        type: 'item',
                        url: '/sequence_modes'
                    },
                    {
                        id: 'traffic_flows',
                        title: 'Traffic flows',
                        type: 'item',
                        url: '/traffic_flows'
                    },
                    {
                        id: 'print_ent_not_certs',
                        title: 'Print ent not certs',
                        type: 'item',
                        url: '/print_ent_not_certs'
                    },
                    {
                        id: 'default_entry_labels',
                        title: 'Default entry labels',
                        type: 'item',
                        url: '/default_entry_labels'
                    },
                    {
                        id: 'overwrite_origins',
                        title: 'Overwrite origins',
                        type: 'item',
                        url: '/overwrite_origins'
                    },
                    {
                        id: 'seven_five_cero_one_declaration_forms',
                        title: '7501 declaration forms',
                        type: 'item',
                        url: '/seven_five_cero_one_declaration_forms'
                    },
                    {
                        id: 'inbond_entered_options',
                        title: 'Inbond entered options',
                        type: 'item',
                        url: '/inbond_entered_options'
                    },
                    {
                        id: 'close_traffic_ie_options',
                        title: 'Close traffic ie options',
                        type: 'item',
                        url: '/close_traffic_ie_options'
                    },
                    {
                        id: 'close_traffic_it_options',
                        title: 'Close traffic it options',
                        type: 'item',
                        url: '/close_traffic_it_options'
                    },
                    {
                        id: 'close_traffic_te_options',
                        title: 'Close traffic te options',
                        type: 'item',
                        url: '/close_traffic_te_options'
                    },
                    {
                        id: 'close_traffics',
                        title: 'Close traffics',
                        type: 'item',
                        url: '/close_traffics'
                    },
                    {
                        id: 'default_aes_form_billed_docs',
                        title: 'Default aes form billed docs',
                        type: 'item',
                        url: '/default_aes_form_billed_docs'
                    },
                    {
                        id: 'management_modes',
                        title: 'Management modes',
                        type: 'item',
                        url: '/management_modes'
                    },
                    {
                        id: 'location_types',
                        title: 'Location types',
                        type: 'item',
                        url: '/location_types'
                    },
                    {
                        id: 'firm_codes',
                        title: 'Firm codes (Location of Goods)',
                        type: 'item',
                        url: '/firm_codes'
                    },
                    {
                        id: 'fee_codes',
                        title: 'Fee codes',
                        type: 'item',
                        url: '/fee_codes'
                    },
                    {
                        id: 'exchange_rates',
                        title: 'Exchange rates',
                        type: 'item',
                        url: '/exchange_rates'
                    },
                ]
            },
            {
                title: 'Defaults / Files / Related',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'manufacturing_ids',
                        title: 'Manufacturing ids',
                        type: 'item',
                        url: '/manufacturing_ids'
                    },
                    {
                        id: 'mode_of_transport',
                        title: 'Mode of transports',
                        type: 'item',
                        url: '/mode_of_transport'
                    },
                    {
                        id: 'ports',
                        title: 'Ports',
                        type: 'item',
                        url: '/ports'
                    },
                    {
                        id: 'del_term',
                        title: 'Del. terms',
                        type: 'item',
                        url: '/del_term'
                    },
                    {
                        id: 'document_templates',
                        title: 'Document templates',
                        type: 'item',
                        url: '/document_templates'
                    },
                    {
                        id: 'related_types',
                        title: 'Related types',
                        type: 'item',
                        url: '/related_types'
                    },
                ]
            },
            {
                title: 'EDI',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'edi_abi_transmissions',
                        title: 'EDI abi transmissions',
                        type: 'item',
                        url: '/edi_abi_transmissions'
                    },
                    {
                        id: 'edi_convert_eda_actions',
                        title: 'EDI convert eda actions',
                        type: 'item',
                        url: '/edi_convert_eda_actions'
                    },
                    {
                        id: 'edi_cvd_add_hts_types',
                        title: 'EDI cvd add hts types',
                        type: 'item',
                        url: '/edi_cvd_add_hts_types'
                    },
                    {
                        id: 'edi_empty_value_lines',
                        title: 'EDI empty value lines',
                        type: 'item',
                        url: '/edi_empty_value_lines'
                    },
                    {
                        id: 'edi_file_error_actions',
                        title: 'EDI file error actions',
                        type: 'item',
                        url: '/edi_file_error_actions'
                    },
                    {
                        id: 'edi_group_entry_lines',
                        title: 'EDI group entry lines',
                        type: 'item',
                        url: '/edi_group_entry_lines'
                    },
                    {
                        id: 'edi_group_sed_lines',
                        title: 'EDI group sed lines',
                        type: 'item',
                        url: '/edi_group_sed_lines'
                    },
                    {
                        id: 'edi_inbond_transmissions',
                        title: 'EDI inbond transmissions',
                        type: 'item',
                        url: '/edi_inbond_transmissions'
                    },
                    {
                        id: 'edi_isf_transmissions',
                        title: 'EDI isf transmissions',
                        type: 'item',
                        url: '/edi_isf_transmissions'
                    },
                    {
                        id: 'edi_lower_hts_types',
                        title: 'EDI lower hts types',
                        type: 'item',
                        url: '/edi_lower_hts_types'
                    },
                    {
                        id: 'edi_on_convert_actions',
                        title: 'EDI on convert actions',
                        type: 'item',
                        url: '/edi_on_convert_actions'
                    },
                    {
                        id: 'edi_overwrite_product_no_exist_actions',
                        title: 'EDI overwrite product no exist actions',
                        type: 'item',
                        url: '/edi_overwrite_product_no_exist_actions'
                    },
                    {
                        id: 'edi_overwrite_use_product_files',
                        title: 'EDI overwrite use product files',
                        type: 'item',
                        url: '/edi_overwrite_use_product_files'
                    },
                    {
                        id: 'edi_product_file_mismatch_actions',
                        title: 'EDI product file mismatch actions',
                        type: 'item',
                        url: '/edi_product_file_mismatch_actions'
                    },
                    {
                        id: 'edi_product_file_updated_actions',
                        title: 'EDI product file updated actions',
                        type: 'item',
                        url: '/edi_product_file_updated_actions'
                    },
                    {
                        id: 'edi_product_no_exist_actions',
                        title: 'EDI product no exist actions',
                        type: 'item',
                        url: '/edi_product_no_exist_actions'
                    },
                    {
                        id: 'edi_sed_transmissions',
                        title: 'EDI sed transmissions',
                        type: 'item',
                        url: '/edi_sed_transmissions'
                    },
                    {
                        id: 'edi_uom_line_mismatches',
                        title: 'EDI uom line mismatches',
                        type: 'item',
                        url: '/edi_uom_line_mismatches'
                    },
                    {
                        id: 'edi_use_product_file_actions',
                        title: 'EDI use product file actions',
                        type: 'item',
                        url: '/edi_use_product_file_actions'
                    },
                ]
            },
            {
                title: 'Misc / DIS Auto Store',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'broker_teams',
                        title: 'Broker Teams',
                        type: 'item',
                        url: '/broker_teams'
                    },
                    {
                        id: 'electronic_export_seds',
                        title: 'Electronic Export Seds',
                        type: 'item',
                        url: '/electronic_export_seds'
                    },
                ]
            },
            {
                title: 'Traffics',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'traffic_types',
                        title: 'Traffic types',
                        type: 'item',
                        url: '/traffic_types'
                    },
                    {
                        id: 'traffic_statuses',
                        title: 'Traffic statuses',
                        type: 'item',
                        url: '/traffic_statuses'
                    }
                ]
            },
            {
                title: 'CBP Transmission Trade',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'entry_summary_query_types',
                        title: 'Entry Summary Query Types',
                        type: 'item',
                        url: '/entry_summary_query_types'
                    }
                ]
            },
            {
                title: 'Entry',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'entry_types',
                        title: 'Entry types',
                        type: 'item',
                        url: '/entry_types'
                    },
                    {
                        id: 'split_shipments',
                        title: 'Split shipments',
                        type: 'item',
                        url: '/split_shipments'
                    },
                    {
                        id: 'payment_types',
                        title: 'Payment types',
                        type: 'item',
                        url: '/payment_types'
                    },
                    {
                        id: 'entry_actions',
                        title: 'Entry actions',
                        type: 'item',
                        url: '/entry_actions'
                    },
                    {
                        id: 'ace_dispositions',
                        title: 'Ace dispositions',
                        type: 'item',
                        url: '/ace_dispositions'
                    },
                    {
                        id: 'status_totals_cert_quota_statuses',
                        title: 'Status totals cert quota statuses',
                        type: 'item',
                        url: '/status_totals_cert_quota_statuses'
                    },
                    {
                        id: 'status_totals_liq_types',
                        title: 'Status totals liq types',
                        type: 'item',
                        url: '/status_totals_liq_types'
                    },
                    {
                        id: 'status_totals_ace_psc_statuses',
                        title: 'Status totals ace psc statuses',
                        type: 'item',
                        url: '/status_totals_ace_psc_statuses'
                    },
                    {
                        id: 'cbp_teams',
                        title: 'Cbp teams',
                        type: 'item',
                        url: '/cbp_teams'
                    },
                    {
                        id: 'inward_man_stickers',
                        title: 'Inward man stickers',
                        type: 'item',
                        url: '/inward_man_stickers'
                    },
                    {
                        id: 'hts_options',
                        title: 'HTS options',
                        type: 'item',
                        url: '/hts_options'
                    },
                    {
                        id: 'fta_options',
                        title: 'FTA options',
                        type: 'item',
                        url: '/fta_options'
                    },
                    {
                        id: 'country_fta_options',
                        title: 'Country FTA options',
                        type: 'item',
                        url: '/country_fta_options'
                    },
                    {
                        id: 'spip_options',
                        title: 'SPIP options',
                        type: 'item',
                        url: '/spip_options'
                    },
                    {
                        id: 'spis_options',
                        title: 'SPIS options',
                        type: 'item',
                        url: '/spis_options'
                    },
                    {
                        id: 'fda_actions',
                        title: 'FDA actions',
                        type: 'item',
                        url: '/fda_actions'
                    },
                    {
                        id: 'fda_pe_actions',
                        title: 'FDA PE actions',
                        type: 'item',
                        url: '/fda_pe_actions'
                    },
                    {
                        id: 'disclaimers',
                        title: 'Disclaimers',
                        type: 'item',
                        url: '/disclaimers'
                    },
                    {
                        id: 'program_codes',
                        title: 'Program codes',
                        type: 'item',
                        url: '/program_codes'
                    },
                    {
                        id: 'processing_codes',
                        title: 'Processing codes',
                        type: 'item',
                        url: '/processing_codes'
                    },
                    {
                        id: 'temp_qualifiers',
                        title: 'Temp qualifiers',
                        type: 'item',
                        url: '/temp_qualifiers'
                    },
                    {
                        id: 'temp_degree_types',
                        title: 'Temp degree types',
                        type: 'item',
                        url: '/temp_degree_types'
                    },
                    {
                        id: 'temp_locations',
                        title: 'Temp locations',
                        type: 'item',
                        url: '/temp_locations'
                    },
                    {
                        id: 'entity_roles',
                        title: 'Entity roles',
                        type: 'item',
                        url: '/entity_roles'
                    },
                    {
                        id: 'country_source_types',
                        title: 'Country source types',
                        type: 'item',
                        url: '/country_source_types'
                    },
                    {
                        id: 'aoc_codes',
                        title: 'FDA Affirmation of Compliance (AOC Codes)',
                        type: 'item',
                        url: '/aoc_codes'
                    },
                    {
                        id: 'fee_accounting_class_codes',
                        title: 'Fee accounting codes',
                        type: 'item',
                        url: '/fee_accounting_class_codes'
                    },
                ]
            },
            {
                title: 'ISF',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'container_types',
                        title: 'Container types',
                        type: 'item',
                        url: '/container_types'
                    },
                    {
                        id: 'isf_transmission_actions',
                        title: 'ISF Transmission actions',
                        type: 'item',
                        url: '/isf_transmission_actions'
                    },
                    {
                        id: 'isf_transmission_reasons',
                        title: 'ISF Transmission reasons',
                        type: 'item',
                        url: '/isf_transmission_reasons'
                    },
                    {
                        id: 'isf_statuses',
                        title: 'ISF Status',
                        type: 'item',
                        url: '/isf_statuses'
                    },
                    {
                        id: 'isf_entity_section_types',
                        title: 'ISF Entity section types',
                        type: 'item',
                        url: '/isf_entity_section_types'
                    },
                    {
                        id: 'isf_entity_types',
                        title: 'ISF Entity types',
                        type: 'item',
                        url: '/isf_entity_types'
                    }
                ]
            },
            {
                title: 'Ships Monitoring',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'shipments',
                        title: 'Shipments',
                        type: 'item',
                        url: '/shipments'
                    }
                ]
            },
            {
                title: 'Inbonds',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'inbond_codes',
                        title: 'Inbond codes',
                        type: 'item',
                        url: '/inbond_codes'
                    },
                    {
                        id: 'inbond_types',
                        title: 'Inbond types',
                        type: 'item',
                        url: '/inbond_types'
                    },
                    {
                        id: 'inbond_action_codes',
                        title: 'Inbond action codes',
                        type: 'item',
                        url: '/inbond_action_codes'
                    }
                ]
            },
            {
                title: 'General Orders',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'go_statuses',
                        title: 'GO Statuses',
                        type: 'item',
                        url: '/go_statuses'
                    }
                ]
            },
            {
                title: 'Arrive/Exports',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'arrive_export_actions',
                        title: 'Arrive/Export actions',
                        type: 'item',
                        url: '/arrive_export_actions'
                    },
                    {
                        id: 'export_mode_of_transports',
                        title: 'Export mode of transports',
                        type: 'item',
                        url: '/export_mode_of_transports'
                    },
                    {
                        id: 'export_codes',
                        title: 'Export codes',
                        type: 'item',
                        url: '/export_codes'
                    },
                    {
                        id: 'export_dfs',
                        title: 'Export DFs',
                        type: 'item',
                        url: '/export_dfs'
                    },
                    {
                        id: 'export_actions',
                        title: 'Export actions',
                        type: 'item',
                        url: '/export_actions'
                    }
                ]
            },
            {
                title: 'Shippers',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'shipper_statuses',
                        title: 'Shipper statuses',
                        type: 'item',
                        url: '/shipper_statuses'
                    },
                    {
                        id: 'sed_labels',
                        title: 'SED Labels',
                        type: 'item',
                        url: '/sed_labels'
                    },
                    {
                        id: 'sed_transmission_actions',
                        title: 'SED Transmission actions',
                        type: 'item',
                        url: '/sed_transmission_actions'
                    },
                    {
                        id: 'license_codes',
                        title: 'License codes',
                        type: 'item',
                        url: '/license_codes'
                    }
                ]
            },
            {
                title: 'EManifests',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'emanif_trans_actions',
                        title: 'Transmission actions',
                        type: 'item',
                        url: '/emanif_trans_actions'
                    },
                    {
                        id: 'emanif_trans_rfas',
                        title: 'Transmission RFAs',
                        type: 'item',
                        url: '/emanif_trans_rfas'
                    },
                    {
                        id: 'trip_statuses',
                        title: 'Trip statuses',
                        type: 'item',
                        url: '/trip_statuses'
                    },
                    {
                        id: 'conveyance_types',
                        title: 'Conveyance types',
                        type: 'item',
                        url: '/conveyance_types'
                    },
                    {
                        id: 'equipment_types',
                        title: 'Equipment types',
                        type: 'item',
                        url: '/equipment_types'
                    },
                    {
                        id: 'equipment_itts',
                        title: 'Equipment ITTs',
                        type: 'item',
                        url: '/equipment_itts'
                    },
                    {
                        id: 'driver_types',
                        title: 'Driver types',
                        type: 'item',
                        url: '/driver_types'
                    },
                    {
                        id: 'cross_doc_types',
                        title: 'Cross document types',
                        type: 'item',
                        url: '/cross_doc_types'
                    },
                    {
                        id: 'shipment_bl_types',
                        title: 'Shipment BL types',
                        type: 'item',
                        url: '/shipment_bl_types'
                    },
                    {
                        id: 'shipment_rfas',
                        title: 'Shipment RFAs',
                        type: 'item',
                        url: '/shipment_rfas'
                    },
                    {
                        id: 'shipment_transmission_actions',
                        title: 'Shipment transmissions actions',
                        type: 'item',
                        url: '/shipment_transmission_actions'
                    }
                ]
            },
            {
                title: 'Product Files',
                type: 'collapse',
                icon: null,
                breadcrumbs: false,
                children: [
                    {
                        id: 'product_file_pga_codes',
                        title: 'PGA Code',
                        type: 'item',
                        url: '/product_file_pga_codes'
                    },
                    {
                        id: 'product_file_pga_processing_codes',
                        title: 'PGA Code',
                        type: 'item',
                        url: '/product_file_pga_processing_codes'
                    },
                    {
                        id: 'aphis_program_codes',
                        title: 'Aphis program codes',
                        type: 'item',
                        url: '/aphis_program_codes'
                    },
                    {
                        id: 'aphis_processing_codes',
                        title: 'Aphis processing codes',
                        type: 'item',
                        url: '/aphis_processing_codes'
                    },
                    {
                        id: 'aphis_types',
                        title: 'Aphis types',
                        type: 'item',
                        url: '/aphis_types'
                    },
                    {
                        id: 'aphis_item_types',
                        title: 'Aphis item types',
                        type: 'item',
                        url: '/aphis_item_types'
                    },
                    {
                        id: 'aphis_category_types',
                        title: 'Aphis category types',
                        type: 'item',
                        url: '/aphis_category_types'
                    },
                    {
                        id: 'aphis_commodity_qualifiers',
                        title: 'Aphis commodity qualifiers',
                        type: 'item',
                        url: '/aphis_commodity_qualifiers'
                    },
                    {
                        id: 'aphis_inspections',
                        title: 'Aphis inspections',
                        type: 'item',
                        url: '/aphis_inspections'
                    },
                    {
                        id: 'upc_quality_codes',
                        title: 'UPC quality codes',
                        type: 'item',
                        url: '/upc_quality_codes'
                    },
                    {
                        id: 'insp_arrival_location_codes',
                        title: 'Insp. Arrival location codes',
                        type: 'item',
                        url: '/insp_arrival_location_codes'
                    },
                    {
                        id: 'intended_use_codes',
                        title: 'Intended use codes',
                        type: 'item',
                        url: '/intended_use_codes'
                    },
                    {
                        id: 'route_types',
                        title: 'Route types',
                        type: 'item',
                        url: '/route_types'
                    },
                    {
                        id: 'txn_types',
                        title: 'Txn types',
                        type: 'item',
                        url: '/txn_types'
                    },
                    {
                        id: 'lpco_types',
                        title: 'Lpco types',
                        type: 'item',
                        url: '/lpco_types'
                    },
                    {
                        id: 'permit_issues',
                        title: 'Permit issues',
                        type: 'item',
                        url: '/permit_issues'
                    },
                    {
                        id: 'date_qualifiers',
                        title: 'Date qualifiers',
                        type: 'item',
                        url: '/date_qualifiers'
                    },
                    {
                        id: 'nhtsa_box_numbers',
                        title: 'Nhtsa box numbers',
                        type: 'item',
                        url: '/nhtsa_box_numbers'
                    },
                    {
                        id: 'nhtsa_program_codes',
                        title: 'Nhtsa program codes',
                        type: 'item',
                        url: '/nhtsa_program_codes'
                    },
                    {
                        id: 'nhtsa_qualifier_numbers',
                        title: 'Nhtsa qualifier numbers',
                        type: 'item',
                        url: '/nhtsa_qualifier_numbers'
                    },
                    {
                        id: 'epa_program_codes',
                        title: 'EPA program codes',
                        type: 'item',
                        url: '/epa_program_codes'
                    },
                    {
                        id: 'remark_codes',
                        title: 'Remark codes',
                        type: 'item',
                        url: '/remark_codes'
                    },

                    {
                        id: 'dea_program_codes',
                        title: 'Dea Program Codes',
                        type: 'item',
                        url: '/dea_program_codes'
                    },
                    {
                        id: 'dea_document_ids',
                        title: 'Dea Document Ids',
                        type: 'item',
                        url: '/dea_document_ids'
                    },
                    {
                        id: 'fsis_program_codes',
                        title: 'Fsis Program Codes',
                        type: 'item',
                        url: '/fsis_program_codes'
                    },
                    {
                        id: 'fsis_inspections',
                        title: 'Fsis Inspections',
                        type: 'item',
                        url: '/fsis_inspections'
                    },
                    {
                        id: 'fsis_lpco_types',
                        title: 'Fsis Lpco Types',
                        type: 'item',
                        url: '/fsis_lpco_types'
                    },
                    {
                        id: 'fsis_lpco_geo_qualifiers',
                        title: 'Fsis Lpco Geo Qualifiers',
                        type: 'item',
                        url: '/fsis_lpco_geo_qualifiers'
                    },
                    {
                        id: 'fsis_category_codes',
                        title: 'Fsis Category Codes',
                        type: 'item',
                        url: '/fsis_category_codes'
                    },
                    {
                        id: 'fsis_commodity_qualifiers',
                        title: 'Fsis Commodity Qualifiers',
                        type: 'item',
                        url: '/fsis_commodity_qualifiers'
                    },
                    {
                        id: 'fws_proc_codes',
                        title: 'Fws Proc Codes',
                        type: 'item',
                        url: '/fws_proc_codes'
                    },
                    {
                        id: 'fws_disclaimers',
                        title: 'Fws Disclaimers',
                        type: 'item',
                        url: '/fws_disclaimers'
                    },
                    {
                        id: 'fws_declaration_codes',
                        title: 'Fws Declaration Codes',
                        type: 'item',
                        url: '/fws_declaration_codes'
                    },
                    {
                        id: 'fws_description_codes',
                        title: 'Fws Description Codes',
                        type: 'item',
                        url: '/fws_description_codes'
                    },
                    {
                        id: 'nmfs370_proc_codes',
                        title: 'Nmfs370 Proc Codes',
                        type: 'item',
                        url: '/nmfs370_proc_codes'
                    },
                    {
                        id: 'ttb_program_codes',
                        title: 'TTB Program Codes',
                        type: 'item',
                        url: '/ttb_program_codes'
                    },
                    {
                        id: 'ttb_lpco_types',
                        title: 'TTB Lpco Types',
                        type: 'item',
                        url: '/ttb_lpco_types'
                    },
                    {
                        id: 'ttb_exemption_codes',
                        title: 'TTB Exemption Codes',
                        type: 'item',
                        url: '/ttb_exemption_codes'
                    },
                    {
                        id: 'product_file_nmfssim_harvdesccodes',
                        title: 'Product File Nmfs SIM Harvest Desc. Codes',
                        type: 'item',
                        url: '/product_file_nmfssim_harvdesccodes'
                    },
                    {
                        id: 'product_file_nmfssim_harvgeartypes',
                        title: 'Product File Nmfs SIM Harvest Gear Types',
                        type: 'item',
                        url: '/product_file_nmfssim_harvgeartypes'
                    },
                    {
                        id: 'product_file_nmfssim_procdesccodes',
                        title: 'Product File Nmfs SIM Processing Desc. Codes',
                        type: 'item',
                        url: '/product_file_nmfssim_procdesccodes'
                    },

                    {
                        id: 'sim_specy_category_codes',
                        title: 'Sim Specy Category Codes',
                        type: 'item',
                        url: '/sim_specy_category_codes'
                    },
                    {
                        id: 'sim_species',
                        title: 'Sim Species',
                        type: 'item',
                        url: '/sim_species'
                    },
                    {
                        id: 'harvest_desc_codes',
                        title: 'Harvest Description Codes',
                        type: 'item',
                        url: '/harvest_desc_codes'
                    },
                    {
                        id: 'harvest_gear_types',
                        title: 'Harvest Gear Types',
                        type: 'item',
                        url: '/harvest_gear_types'
                    },
                    {
                        id: 'nmfs_sim_processing_codes',
                        title: 'Nmfs SIM Processing Codes',
                        type: 'item',
                        url: '/nmfs_sim_processing_codes'
                    },
                    {
                        id: 'harvest_vessel_char_types',
                        title: 'Harvest Vessel Char Types',
                        type: 'item',
                        url: '/harvest_vessel_char_types'
                    }
                ]
            },
        ]
    });
}

export default management;
