import {Component} from 'react'
//import {NotificationManager} from 'react-notifications'

class MessageService extends Component {
	static sendMessage(kind, message, action, action_text) {
		let iframe_container = document.getElementById('ai-agent');
		let iframe = document.getElementById('ai-agent-iframe');
		
		iframe.contentWindow.postMessage({
			type: 'ai-agent-message', 
			kind: kind, 
			content: message, 
			action: action,
			action_text: action_text
		}, "*");
		
		iframe_container.classList.add('oppened');

		/*setTimeout(() => {
			iframe_container.classList.remove('oppened');
		}, 3000);*/
	}
	
    static showSuccessNotification(message, action=null, action_text=null) {
		MessageService.sendMessage('success', message, action, action_text);

        /*NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.success(message + '.', 'Earground', 1500);*/
    }

    static showInfoNotification(message, action=null, action_text=null) {
		MessageService.sendMessage('info', message, action, action_text);

        /*NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.success(message + '.', 'Earground', 3000);*/
    }    

    static showWarningNotification(message, action=null, action_text=null) {
		MessageService.sendMessage('warning', message, action, action_text);

        /*NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.warning(message + '.', 'Earground', 1500);*/
    }

    static showErrorNotification(message, action=null, action_text=null) {
		MessageService.sendMessage('error', message, action, action_text);

        /*NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.error(message + '.', 'Earground', 1500);*/
    }
}

export default MessageService;
